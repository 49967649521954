<template>
  <v-container fill-height fluid pl-0 pr-0>
    <v-row align="center" class="justify-center">
      <v-btn
            align='center'
            text
            color="primary" @click="print">Print
      </v-btn>
    </v-row>
    <v-row id="printMe">
      <v-card
        class="mx-auto mt-0"
        width="1000"
      >
        <v-card-title class="justify-center">
            <span class="title font-weight-light justify-center">{{medication[0]['resident']['fullName']}}</span>
        </v-card-title>
      </v-card>
    <v-col cols="12" lg="6" md="6">
      <v-card
        class="mx-auto mt-2 pb-0 mb-0"
        width="1000"
      >
        <v-card-text>
            <div class="text--primary">
              <p class="mb-8"><b>Medication:</b> {{medication[0]['resident']['medication']}}</p>
              <p class="mb-8"><b>Medication name:</b> {{medication[0]['name']}}</p>
              <p class="mb-8"><b>Directive: </b>{{medication[0]['directive']}}</p>
              <p class="mb-8"><b>Description:</b> {{medication[0]['description']}}</p>
              <p class="mb-8"><b>Receive Time:</b> {{medication[0]['receiveTime']}}</p>
              <p class="mb-8"><b> Quantity Order:</b> {{medication[0]['quantity']['order']}}</p>
              <p class="mb-8"><b> Quantity Receive:</b> {{medication[0]['quantity']['receive']}}</p>
            </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" md="6">
        <v-card
        class="mx-auto mt-2 pb-0 mb-0"
        width="1000"
      >
        <v-card-text>
            <div class="text--primary">
                <p class="mb-8"><b> Type:</b> {{medication[0]['type']}}</p>
                <p class="mb-8"><b> Pharmacy:</b> {{medication[0]['pharmacy']}}</p>
                <p class="mb-8"><b> Start Date:</b> {{medication[0]['startDate']}}</p>
                <p class="mb-8"><b> End Date:</b> {{medication[0]['endDate']}}</p>
                <p class="mb-8"><b> Witness:</b> {{medication[0]['witness']}}</p>
                <p class="mb-8"><b> Date Of Birth:</b> {{medication[0]['resident']['dateOfBirth']}}</p>
                <p class="mb-8"><b> Funding:</b> {{medication[0]['resident']['funding']}}</p>
            </div>
        </v-card-text>
      </v-card>
    </v-col>
    <img height="50" width="50" src="../../assets/logo.png" class="mx-4">
    </v-row>
  </v-container>
</template>

<script>
import medicationApi from '../../services/api/MedicationApi';
import residentApi from '../../services/api/ResidentApi';
export default {
    name: 'medicationDetail',
    data() {
        return {
            medication: '',
            totalMedication:'',
            medicationId:'',
            residentId:''
        }
    },
    computed: {

    },
    created() {
        this.medicationId = this.$route.params.medicationId;
        this.residentId = this.$route.params.residentId;
        console.log(this.medicationId)
        console.log(this.residentId);
        this.get_registered_medication();
    },
    methods: {
        get_registered_medication () {
          this.loading = true
          return new Promise((resolve, reject) => {
              medicationApi.getRegisteredMedication({page: '', count:'', id:this.residentId, q:'', orderBy:'', order: ''})
                .then(medications => {
                    this.medication = medications.result.filter(item => item.id === this.medicationId)
                    this.totalMedication = this.medication.length
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        load_more(residentId){
          this.residentId = residentId;
          return new Promise((resolve, reject) => {
              residentApi.getResident({residentId})
                .then(resident => {
                    this.resident = resident.result
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        print () {
          this.$htmlToPaper('printMe');
        }
    }
}
</script>

<style scoped lang="scss">
  #block {
    float: left;
  }
</style>